@import "../../utils/style-constants";

.upload_phone_numbers__picker__container {
    width: 100%;
    height: 200px !important;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    position: relative input {
        display: none;
    }

    label {
        width: 100%;
    }
}
.file_upload_input {
    display: none;
}
.upload_phone_numbers__picker__button {
    width: 100% !important;
    color: rgba(0, 0, 0, 0.7) !important;
    text-transform: none;

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .upload_phone_numbers_icon {
        height: 50px;
        width: 50px;
        margin: 0;
    }
}

.upload_phone_numbers__picker__button:hover {
    color: $deep_ocean_blue !important;
}

.upload__input {
    opacity: 0.5;
  }